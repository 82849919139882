import React, {useContext, useEffect} from "react";
import GlobalContext from "../context/GlobalContext";
import Sidebar from '../components/Sidebar';
import Header from '../components/Header';
import HomeSlider from '../components/HomeSlider';
import ContactMe from '../components/ContactMe';
import { NavLink, Link } from "react-router-dom";

import SwiperCore, { Pagination, Autoplay } from 'swiper';
SwiperCore.use([Pagination, Autoplay]);

const Home = () => {
    const {language, setLanguage} = useContext(GlobalContext);
    const isMobile = window.innerWidth < 992;
    
    useEffect(() => {
        document.body.classList.remove('hide-sidebar-mobile');
    },[]); 

    return (
        <div>
            <Sidebar/>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-9 offset-md-3 col-index">
                        <Header />

                        <div className="content-div p-30 col-md-12">
                            <div className="row">
                                <div className="col-md-7">
                                    <span className="w-full block bg-pink m-0 rounded-top-small page-title"></span>
                                        <div className="bg-editor-gray white p-15 mb-30 rounded-bottom-small mobile-text-center">
                                            <div className="row">
                                                <div className="col-md-10">
                                                    {language == 'en' ?
                                                        <h1 className="pink font-comfortaa font-57 m-0">Hello!</h1>
                                                    :''}
                                                    {language == 'pt' ?
                                                        <h1 className="pink font-comfortaa font-57 m-0">Olá!</h1>
                                                    :''}
                                                    {language == 'en' ?
                                                        <p className="mt-20 mb-20">
                                                            Welcome to my website. Here you will learn more about my skills, projects, places where I worked, and see my portfolio. 
                                                            <br/><br/> 
                                                            I'm a front-end developer since 2015, focused in developing quality code, good practices, responsiveness, problem-solving and accesibily.
                                                        </p>
                                                    :''}
                                                    {language == 'pt' ?
                                                        <p className="mt-20 mb-20">
                                                            Bem-vindo(a) ao meu site. Aqui você irá saber mais sobre minhas habilidades, projetos, lugares que trabalhei e ver meu portfólio. 
                                                            <br/><br/> 
                                                            Sou desenvolvedora front-end desde 2015, focada em desenvolver código de qualidade, boas práticas, responsividade, solução de problemas e acessibilidade. <br />
                                                        </p>
                                                    :''}

                                                    {language == 'en' ?
                                                        <Link to="/projects">
                                                            <a className="w-300 shrink-border shrink-pink mb-10 mt-10 w-full text-center mb-30 btn-full-mobile">See my projects</a>
                                                        </Link>
                                                    :''}
                                                    {language == 'pt' ?
                                                        <Link to="/projects">
                                                            <a to="/projects" className="w-300 shrink-border shrink-pink mb-10 mt-10 w-full text-center mb-30 btn-full-mobile">Veja meus projetos</a>
                                                        </Link>
                                                    :''}
                                                </div>
                                            </div>
                                        </div>

                                        {isMobile?
                                            <div className="mobile-mb-30">
                                                <HomeSlider />
                                            </div>
                                        :''}

                                        {language == 'en' ?
                                            <h2 className="dark-pink font-comfortaa font-30 mobile-text-center">Skills</h2>
                                        :''}
                                        {language == 'pt' ?
                                            <h2 className="dark-pink font-comfortaa font-30 mobile-text-center">Conhecimento</h2>
                                        :''}
                                        
                                        <div className="row mt-20 mb-20">
                                            <div className="col-md-4">
                                                <ul>
                                                    <li className="mb-5">
                                                        {language == 'en' ?
                                                            <span className="bg-blue-usa skill tag white align-middle mb-2">
                                                                <i className="icon icon-chat bg-white align-middle"></i>
                                                                English/Portuguese
                                                            </span>
                                                        :''}
                                                        {language == 'pt' ?
                                                            <span className="bg-blue-usa skill tag white align-middle mb-2">
                                                            <i className="icon icon-chat bg-white align-middle"></i>
                                                            Inglês/Português
                                                        </span>                                                        
                                                        :''}
                                                    </li>
                                                    <li className="mb-5">
                                                        <span className="bg-red-html skill tag white align-middle mb-2">
                                                            <i className="icon icon-html bg-white align-middle"></i>
                                                            HTML
                                                        </span>
                                                    </li>
                                                    <li className="mb-5">
                                                        <span className="bg-blue-css skill tag white align-middle mb-2">
                                                            <i className="icon icon-css bg-white align-middle"></i>
                                                            CSS
                                                        </span>
                                                    </li>
                                                    <li className="mb-5">
                                                        {language == 'en' ?
                                                            <span className="bg-purple-xd skill tag white align-middle mb-2">
                                                                <i className="icon icon-file bg-white align-middle"></i>
                                                                Layout to HTML
                                                            </span>
                                                        :''}
                                                        {language == 'pt' ?
                                                            <span className="bg-purple-xd skill tag white align-middle mb-2">
                                                                <i className="icon icon-file bg-white align-middle"></i>
                                                                Layout para HTML
                                                            </span>                                                     
                                                        :''}
                                                    </li>
                                                    <li className="mb-5">
                                                        {language == 'en' ?
                                                        <span className="bg-green skill tag white align-middle mb-2">
                                                            <i className="icon icon-responsive bg-white align-middle"></i>
                                                            Responsive Websites
                                                        </span>
                                                        :''}
                                                        {language == 'pt' ?
                                                        <span className="bg-green skill tag white align-middle mb-2">
                                                            <i className="icon icon-responsive bg-white align-middle"></i>
                                                            Websites Responsivos
                                                        </span>                                                  
                                                        :''}
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="col-md-4">
                                                <ul>
                                                    <li className="mb-5">
                                                        <span className="bg-yellow-javascript skill tag white align-middle mb-2">
                                                            <i className="icon icon-css bg-white align-middle"></i>
                                                            JavaScript Vanilla
                                                        </span>
                                                    </li>
                                                    <li className="mb-5">
                                                        <span className="bg-blue-jquery skill tag white align-middle mb-2">
                                                            <i className="icon icon-jquery bg-white align-middle"></i>
                                                            JQuery
                                                        </span>
                                                    </li>
                                                    <li className="mb-5">
                                                        <span className="bg-blue-reactjs skill tag white align-middle mb-2">
                                                            <i className="icon icon-reactjs bg-white align-middle"></i>
                                                            ReactJS
                                                        </span>
                                                    </li>
                                                    <li className="mb-5">
                                                        <span className="bg-red-angular skill tag white align-middle mb-2">
                                                            <i className="icon icon-angular bg-white align-middle"></i>
                                                            AngularJS
                                                        </span>
                                                    </li>
                                                    <li className="mb-5">
                                                        <span className="bg-blue-ionic skill tag white align-middle mb-2">
                                                            <i className="icon icon-ionic bg-white align-middle"></i>
                                                            Ionic
                                                        </span>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="col-md-4">
                                                <ul>
                                                    <li className="mb-5">
                                                        <span className="bg-blue-wordpress skill tag white align-middle mb-2">
                                                            <i className="icon icon-wordpress bg-white align-middle"></i>
                                                            Wordpress
                                                        </span>
                                                    </li>  
                                                    <li className="mb-5">
                                                        <span className="bg-green-shopify skill tag white align-middle mb-2">
                                                            <i className="icon icon-shopify bg-white align-middle"></i>
                                                            Shopify
                                                        </span>
                                                    </li>  
                                                    <li className="mb-5">
                                                        <span className="bg-purple skill tag white align-middle mb-2">
                                                            <i className="icon icon-seo bg-white align-middle"></i>
                                                            SEO Markup
                                                        </span>
                                                    </li>
                                                    <li className="mb-5">
                                                        <span className="bg-black skill tag white align-middle mb-2">
                                                            <i className="icon icon-bigcommerce bg-white align-middle"></i>
                                                            BigCommerce
                                                        </span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                {language == 'en' ?
                                                    <Link to="/experience">
                                                        <a className="w-300 shrink-border mb-10 mt-10 w-full text-center">See my experience</a>
                                                    </Link>
                                                :''}
                                                {language == 'pt' ?
                                                    <Link to="/experience">
                                                        <a className="w-300 shrink-border mb-10 mt-10 w-full text-center">Veja minha experiência</a>
                                                    </Link>
                                                :''}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-5 pl-30 mobile-pl-15">
                                        {!isMobile?
                                            <HomeSlider />
                                        :''}
                                        <ContactMe />
                        
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default Home;