import React, {useContext, useEffect, useState} from "react";
import { NavLink } from "react-router-dom";
import GlobalContext from "../context/GlobalContext";

import foto from "../img/andressa_5.png";

const Sidebar = () =>{
    const {language, setLanguage} = useContext(GlobalContext);
    const {toggleMenu, setToggleMenu} = useContext(GlobalContext);

    const menuMobile = () => {
        setToggleMenu(false);
        document.getElementById('navMenu').classList.remove('open');
    }

    return(
        <div id="mobileSibebar">
            <div id="canvas" className="top-0"></div>
            <div className="container-photo container-fluid fixed-desktop w-full top-0 bottom-0 left-0 right-0">
                <div className="row h-full">
                    <div className="col-md-3 p-0 h-full w-full-mobile">
                        <aside className="sidebar relative bg-pink text-center p-30 h-full shadow balls" id="sidebar">
                            <div className="sidebar-photo bg-pink border-5-pink bg-cover bg-center w-200 h-200 rounded-full mx-auto mb-20 relative z-1" 
                            style={{backgroundImage: `url(${foto})`}}></div>
                        </aside>
                    </div>
                </div>
            </div>
            <div className="container-content container-fluid fixed-desktop w-full top-0 bottom-0 left-0 right-0">
                <div className="row h-full">
                    <div className="col-md-3 p-0 h-full w-full-mobile">
                        <aside className="sidebar-wrapper relative text-center p-30 h-full shadow balls" id="sidebar">
                            <div className="sidebar-content">
                                <h2 className="font-28 dark-pink font-comfortaa font-black-hack">Andressa Bertolini</h2>
                                <h3 className="mb-30 font-20 white">Front-end Developer</h3>
                                {language == 'en' ?
                                    <p className="white mb-20">I'm a front-end developer from <strong>Brazil</strong> with <ins>{new Date().getFullYear() - 2015} years</ins> of experience in websites and applications development.</p>
                                :''}
                                {language == 'pt' ?
                                    <p className="white mb-20">Desenvolvedora com <ins>{new Date().getFullYear() - 2015} anos</ins> de experiência em desenvolver sites e aplicações web.</p>
                                :''}
                                <div className="mb-30">
                                    <a href="https://github.com/andressa-bertolini" target="_blank" className="mr-8">
                                        <i className="icon icon-github bg-white hover-size"></i>
                                    </a>
                                    <a href="https://br.linkedin.com/in/andressa-bertolini-b46494b2" target="_blank">
                                        <i className="icon icon-linkedin bg-white hover-size"></i>
                                    </a>
                                </div>

                                <nav className="sidebar-menu" id="navMenu" onClick={() => menuMobile()}>
                                    <NavLink to="/" exact className="item-menu white align-middle">
                                        <i className="icon icon-home bg-white mr-8 align-middle"></i>  
                                        <span className="align-middle">Home</span>
                                    </NavLink>

                                    {language == 'en' ?
                                    <NavLink to="/projects" className="item-menu white align-middle">
                                        <i className="icon icon-coding bg-white mr-8 align-middle"></i>
                                        <span className="align-middle">Projects</span>
                                    </NavLink>
                                    :''}
                                    {language == 'pt' ?
                                    <NavLink to="/projetos" className="item-menu white align-middle">
                                        <i className="icon icon-coding bg-white mr-8 align-middle"></i>
                                        <span className="align-middle">Projetos</span>
                                    </NavLink>
                                    :''}

                                    {language == 'en' ?
                                    <NavLink to="/experience" className="item-menu white align-middle">
                                        <i className="icon icon-portfolio bg-white mr-8 align-middle"></i>
                                        <span className="align-middle">Experience/ Education</span>
                                    </NavLink>
                                    :''}
                                    {language == 'pt' ?
                                    <NavLink to="/experiencia" className="item-menu white align-middle">
                                        <i className="icon icon-portfolio bg-white mr-8 align-middle"></i>
                                        <span className="align-middle">Experiência/ Formação</span>
                                    </NavLink>
                                    :''}

                                    {language == 'en' ?
                                    <NavLink to="/contact" className="item-menu white align-middle">
                                        <i className="icon icon-mail bg-white mr-8 align-middle"></i>
                                        <span className="align-middle">Contact</span>
                                    </NavLink>
                                    :''}
                                    {language == 'pt' ?
                                    <NavLink to="/contato" className="item-menu white align-middle">
                                        <i className="icon icon-mail bg-white mr-8 align-middle"></i>
                                        <span className="align-middle">Contato</span>
                                    </NavLink>
                                    :''}

                                </nav>
                            </div>
                        </aside>
                    </div>
                </div>
            </div>
        </div>
    );
 
}

export default Sidebar;