import React from "react";

class CardCompany extends React.Component{
    render(){
        return(
            <div className={(this.props.university == false ? "header-line":"") + " bg-white border-gray shadow dark-gray h-full p-15 rounded-small dark-mode-bg dark-mode-white"}>
                <p className="text-center card-company-year bold dark-pink">{this.props.year}</p>
                <div className="bg-light-gray bg-cover bg-center w-80 h-80 rounded-full mx-auto mb-10 relative z-1 border-5-gray" 
                style={{backgroundImage: `url(${this.props.image})`,marginTop: '-56px'}}></div>

                <p className="text-center text-uppercase font-10">
                    {this.props.country == 'Brasil' || this.props.country == 'Brazil'?
                        <i className="icon-brazil icon-small mr-3 align-middle"></i> 
                    :''}
                    {this.props.country == 'EUA' || this.props.country == 'USA'?
                        <i className="icon-usa icon-small mr-3 align-middle"></i> 
                    :''}
                    {this.props.country}
                </p>
                <h3 className="text-center font-16 bold m-0">{this.props.name}</h3>
                <p className="text-center text-uppercase font-10 mb-20">{this.props.type}</p>

                <h3 className="font-16 bold m-0">{this.props.position}</h3>
                <p className="text-uppercase font-10 mb-20">{this.props.period}</p>

                <p className="font-14">{this.props.description}</p>
            </div>            
        )
    }
}

export default CardCompany;