import React, { useContext, useState, useEffect } from "react";
import Sidebar from "../components/Sidebar";
import Header from '../components/Header';
import PageTitle from "../components/PageTitle";
import GlobalContext from "../context/GlobalContext";

import load from "../img/load.gif";

import axios from 'axios';
const API_PATH = '../php/contact.php';

const Contact = () =>{
    const {language, setLanguage} = useContext(GlobalContext);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [feedback, setFeedback] = useState(false);
    const [feedbackMessage, setFeedbackMessage] = useState("");
    const [loading, setLoading] = useState(false);

    const handleFormSubmit = (e) => {
        e.preventDefault();

        setFeedback(false);
        setLoading(true);

        var elems = document.getElementsByClassName('validate');

        for(var i = 0; i < elems.length; i++) {
            elems[i].classList.remove('active');
        }

        if(name != '' && email != '' && validateEmail(email) && message != ''){
            axios.post(`${API_PATH}`,JSON.stringify({ 
            
                data: {
                    name: name,
                    email: email,
                    message: message,
                }})
            ).then(function(response){
                console.log(response);
                setLoading(false);
                setFeedback(true);

                if(language == 'en'){
                    setFeedbackMessage("Your message has been sent successfully.");
                }

                if(language == 'pt'){
                    setFeedbackMessage("Mensagem enviada com sucesso.");
                }

                setName("");
                setEmail("");
                setMessage("");
                
            }).catch(function (error) {
                console.log(error);
                setLoading(false);
                setFeedback(true);

                if(language == 'en'){
                    setFeedbackMessage("Error. Please try again.");
                }

                if(language == 'pt'){
                    setFeedbackMessage("Erro. Por favor tente novamente.");
                }
            });
                        
        }else{
            
            if(name == ''){
                document.getElementById('emptyName').classList.add('active');
            }
            if(email == ''){
                document.getElementById('emptyEmail').classList.add('active');
            }else{
                if(!validateEmail(email)){
                    document.getElementById('invalidEmail').classList.add('active');
                }                
            }
            if(message == ''){
                document.getElementById('emptyMessage').classList.add('active');
            }
            setLoading(false);
        }
    }

    const validateEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    useEffect(() => {
        document.body.classList.add('hide-sidebar-mobile');
    },[]);

    return(
        <div>
            <Sidebar />

            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-9 offset-md-3 col-index">
                        <Header/>
                        <div className="content-div p-30 col-md-12"> 
                            {language == 'en'?                          
                                    <PageTitle
                                        title="Contact"
                                        description="Send me a message and I'll answer you ASAP."
                                    />
                                :''} 
                                {language == 'pt'?                          
                                    <PageTitle
                                        title="Contato"
                                        description="Envie sua mensagem e responderei o mais breve possível."
                                    />
                                :''} 
                            <div className="row">
                                <div className="col-md-7">
                                    <form action="../php/contact.php">
                                        <div className="row">
                                            <div className="col-md-12 mb-30 relative">
                                                <label htmlFor="name" className="dark-pink dark-mode-pink bold">
                                                    {language == 'en'?
                                                        <span>Name</span>
                                                    :''}
                                                    {language == 'pt'?
                                                        <span>Nome</span>
                                                    :''}
                                                </label>
                                                <input type="text" className="input-style dark-mode-white dark-mode-border-pink" name="name" id="name" required
                                                value={name}
                                                onChange={e => setName(e.target.value)}
                                                />
                                                <div className="validate" id="emptyName">
                                                    {language == 'en'?
                                                        <span className="text-feedback">Fill in the name</span>
                                                    :''}
                                                     {language == 'pt'?
                                                        <span className="text-feedback">Preencha o nome</span>
                                                    :''}
                                                </div>
                                            </div>
                                            <div className="col-md-12 mb-30 relative">
                                                <label htmlFor="email" className="dark-pink dark-mode-pink bold">Email</label>
                                                <input type="email" className="input-style dark-mode-white dark-mode-border-pink" id="email" name="email" required
                                                value={email}
                                                onChange={e => setEmail(e.target.value)}
                                                />
                                                <div className="validate" id="emptyEmail">
                                                    {language == 'en'?
                                                        <span className="text-feedback">Fill in the email</span>
                                                    :''}
                                                     {language == 'pt'?
                                                        <span className="text-feedback">Preencha o e-mail</span>
                                                    :''}
                                                                                                    </div>
                                                <div className="validate" id="invalidEmail">
                                                    {language == 'en'?
                                                        <span className="text-feedback">Invalid E-mail</span>
                                                    :''}
                                                     {language == 'pt'?
                                                        <span className="text-feedback">E-mail inválido</span>
                                                    :''}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="relative mb-30">
                                            <label htmlFor="message" className="dark-pink dark-mode-pink bold">
                                                {language == 'en'?
                                                    <span>Message</span>
                                                :''}
                                                {language == 'pt'?
                                                    <span>Mensagem</span>
                                                :''}
                                            </label>
                                            <textarea id="message" name="message" required className="w-full input-style dark-mode-white dark-mode-border-pink"
                                            onChange={e => setMessage(e.target.value)}
                                            value={message}
                                            ></textarea>
                                            <div className="validate" id="emptyMessage">
                                                {language == 'en'?
                                                    <span className="text-feedback">White a message</span>
                                                :''}
                                                    {language == 'pt'?
                                                    <span className="text-feedback left-0">Escreva a mensagem</span>
                                                :''}
                                            </div>
                                        </div>

                                        {feedback ?
                                            <div className="p-8 rounded-small bg-light-gray">{feedbackMessage}</div>
                                        :''}
                                        
                                        <div className={(loading?"events-disabled":"")+" relative inline-block w-full-mobile"}>
                                            {language == 'en'?
                                                <button type="submit" onClick={e => handleFormSubmit(e)} className="shrink-border shrink-pink-dark-mode mb-10 mt-10 w-300">Send</button>
                                            :''}
                                            {language == 'pt'?
                                                <button type="submit" onClick={e => handleFormSubmit(e)} className="shrink-border shrink-pink-dark-mode mb-10 mt-10 w-300">Enviar</button>
                                            :''}
                                            {loading ?
                                                <div className="loading-btn absolute">
                                                    <img src={load} />
                                                </div>
                                            :''}
                                        </div>
                                        
                                    </form>

                                </div>     
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
      
}


export default Contact;