import React, {useContext} from "react";
import GlobalContext from "../context/GlobalContext";

import projectGaleriaDoRock from "../img/project-galeria-do-rock.jpg";
import projectTurmaDaMonicaOFilme from "../img/project-turma-da-monica-o-filme.jpg";
import projectHarryPotterQuebraCabeca3D from "../img/project-harry-potter-quebra-cabeca-3d.jpg";
import projectBradescoSeguro from "../img/project-bradesco-seguros.jpg";
import projectSulamericaAssistenciaInventario from "../img/project-sulamerica-assistencia-inventario.jpg";

import { Swiper, SwiperSlide } from 'swiper/react';

const HomeSlider = () =>{
    const {language, setLanguage} = useContext(GlobalContext);

    return(
        <>
        {language == 'en'?
        <Swiper
        autoplay={{ delay: 3000 }}
        pagination={{clickable: true}}
        spaceBetween={50}
        slidesPerView={1}
        // onSlideChange={() => console.log('slide change')}
        // onSwiper={(swiper) => console.log(swiper)}
        >
            <SwiperSlide>
                <div className="bg-white border-gray shadow dark-gray h-full slide-content rounded-small overflow-hidden dark-mode-bg dark-mode-white">
                    <img src={projectTurmaDaMonicaOFilme} className="w-full"/>
                    <div className="p-15">
                        <div className="row mb-20">
                            <div className="col-md-6">
                                <h3 className="dark-pink font-16 m-0 bold">Turma da Mônica The Movie</h3>
                                <p className="text-uppercase font-10">Movie Website</p>
                            </div>
                            <div className="col-md-6 text-center">
                                <span className="bg-purple-xd tag white align-middle mb-2">
                                    <i className="icon icon-file bg-white hover-size align-middle mr-8"></i>
                                    Layout to HTML
                                </span>
                                <p className="text-uppercase font-10">Developed for Company</p>
                            </div>
                        </div>
                        <p className="font-14 mb-10">The website of the live action movie of the classical brazilian comics 'Turma da Mônica'.</p>
                        {/* <div className="absolute bottom-0 left-0 right-0">
                            <a href="http://andressabertolini.com/project/turma-da-monica-o-filme" target="_blank" className="shrink-border mb-10 mt-10 w-full text-center">Live</a>
                        </div> */}
                    </div>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className="bg-white border-gray shadow dark-gray h-full slide-content rounded-small overflow-hidden dark-mode-bg dark-mode-white">
                    <img src={projectGaleriaDoRock} className="w-full"/>
                    <div className="p-15">
                        <div className="row mb-20">
                            <div className="col-md-6">
                                <h3 className="dark-pink font-16 m-0 bold">Rock Gallery</h3>
                                <p className="text-uppercase font-10">Institucional Website</p>
                            </div>
                            <div className="col-md-6 text-center">
                                <span className="bg-purple-xd tag white align-middle mb-2">
                                    <i className="icon icon-file bg-white hover-size align-middle mr-8"></i>
                                    Layout to HTML
                                </span>
                                <p className="text-uppercase font-10">Developed for Company</p>
                            </div>
                        </div>
                        <p className="font-14 mb-10">Website of the iconic build, and so tourist spot in São Paulo, Rock Gallery. You can find a lot of Rock N' Roll there.</p>
                        {/* <div className="absolute bottom-0 left-0 right-0">
                            <a href="http://andressabertolini.com/project/galeria-do-rock" target="_blank" className="shrink-border mb-10 mt-10 w-full text-center">Live</a>
                        </div> */}
                    </div>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className="bg-white border-gray shadow dark-gray h-full slide-content rounded-small overflow-hidden dark-mode-bg dark-mode-white">
                    <img src={projectHarryPotterQuebraCabeca3D} className="w-full"/>
                    <div className="p-15">
                        <div className="row mb-20">
                            <div className="col-md-6">
                                <h3 className="dark-pink font-16 m-0 bold">Harry Potter 3D Puzzle</h3>
                                <p className="text-uppercase font-10">Product Landing Page</p>
                            </div>
                            <div className="col-md-6 text-center">
                                <span className="bg-purple-xd tag white align-middle mb-2">
                                    <i className="icon icon-file bg-white hover-size align-middle mr-8"></i>
                                    Layout to HTML
                                </span>
                                <p className="text-uppercase font-10">Developed for Company</p>
                            </div>
                        </div>
                        <p className="font-14 mb-10">This landing page was developed for divulge the 3d puzzle series of Harry Potter.</p>
                        {/* <div className="absolute bottom-0 left-0 right-0">
                            <a href="http://andressabertolini.com/project/harry-potter-3d" target="_blank" className="shrink-border mb-10 mt-10 w-full text-center">Live</a>
                        </div> */}
                    </div>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className="bg-white border-gray shadow dark-gray h-full slide-content rounded-small overflow-hidden dark-mode-bg dark-mode-white">
                    <img src={projectBradescoSeguro} className="w-full"/>
                    <div className="p-15">
                        <div className="row mb-20">
                            <div className="col-md-6">
                                <h3 className="dark-pink font-16 m-0 bold">Bradesco Insurance</h3>
                                <p className="text-uppercase font-10">Landing Page</p>
                            </div>
                            <div className="col-md-6 text-center">
                                <span className="bg-purple-xd tag white align-middle mb-2">
                                    <i className="icon icon-file bg-white hover-size align-middle mr-8"></i>
                                    Layout to HTML
                                </span>
                                <p className="text-uppercase font-10">Developed for Company</p>
                            </div>
                        </div>
                        <p className="font-14 mb-10">Landing page for a bank who offers car insurance.</p>
                        {/* <div className="absolute bottom-0 left-0 right-0">
                            <a href="http://andressabertolini.com/project/bradesco-seguros" target="_blank" className="shrink-border mb-10 mt-10 w-full text-center">Live</a>
                        </div> */}
                    </div>
                </div>
            </SwiperSlide>
            <SwiperSlide>
            <div className="bg-white border-gray shadow dark-gray h-full slide-content rounded-small overflow-hidden dark-mode-bg dark-mode-white">
                <img src={projectSulamericaAssistenciaInventario} className="w-full"/>
                <div className="p-15">
                    <div className="row mb-20">
                        <div className="col-md-6">
                            <h3 className="dark-pink font-16 m-0 bold">Sulamerica Inventory Assistance</h3>
                            <p className="text-uppercase font-10">Form page</p>
                        </div>
                        <div className="col-md-6 text-center">
                            <span className="bg-purple-xd tag white align-middle mb-2">
                                <i className="icon icon-file bg-white hover-size align-middle mr-8"></i>
                                Layout to HTML
                            </span>
                            <p className="text-uppercase font-10">Developed for Company</p>
                        </div>
                    </div>
                    <p className="font-14 mb-10">This website is an inventory assistance form with 4 pages and a lot of different types of inputs.</p>
                    {/* <div className="absolute bottom-0 left-0 right-0">
                        <a href="http://andressabertolini.com/project/sulamerica" target="_blank" className="shrink-border mb-10 mt-10 w-full text-center">Live</a>
                    </div> */}
                </div>
            </div>
            </SwiperSlide>
        </Swiper> 
        :''}
        {language == 'pt'?
        <Swiper
        autoplay={{ delay: 3000 }}
        pagination={{clickable: true}}
        spaceBetween={50}
        slidesPerView={1}
        onSlideChange={() => console.log('slide change')}
        onSwiper={(swiper) => console.log(swiper)}
        >
            <SwiperSlide>
                <div className="bg-white border-gray shadow dark-gray h-full slide-content rounded-small overflow-hidden dark-mode-bg dark-mode-white">
                    <img src={projectTurmaDaMonicaOFilme} className="w-full"/>
                    <div className="p-15">
                        <div className="row mb-20">
                            <div className="col-md-6">
                                <h3 className="dark-pink font-16 m-0 bold">Turma da Mônica O Filme</h3>
                                <p className="text-uppercase font-10">Website do filme</p>
                            </div>
                            <div className="col-md-6 text-center">
                                <span className="bg-purple-xd tag white align-middle mb-2">
                                    <i className="icon icon-file bg-white hover-size align-middle mr-8"></i>
                                    Layout para HTML
                                </span>
                                <p className="text-uppercase font-10">Devenvolvido para Empresa</p>
                            </div>
                        </div>
                        <p className="font-14 mb-10">O website de divulgação do filme live action dos clássicos dos quadrinhos Turma da Mônica.</p>
                        {/* <div className="absolute bottom-0 left-0 right-0">
                            <a href="http://andressabertolini.com/project/turma-da-monica-o-filme" target="_blank" className="shrink-border mb-10 mt-10 w-full text-center">Live</a>
                        </div> */}
                    </div>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className="bg-white border-gray shadow dark-gray h-full slide-content rounded-small overflow-hidden dark-mode-bg dark-mode-white">
                    <img src={projectGaleriaDoRock} className="w-full"/>
                    <div className="p-15">
                        <div className="row mb-20">
                            <div className="col-md-6">
                                <h3 className="dark-pink font-16 m-0 bold">Rock Gallery</h3>
                                <p className="text-uppercase font-10">Institucional Website</p>
                            </div>
                            <div className="col-md-6 text-center">
                                <span className="bg-purple-xd tag white align-middle mb-2">
                                    <i className="icon icon-file bg-white hover-size align-middle mr-8"></i>
                                    Layout to HTML
                                </span>
                                <p className="text-uppercase font-10">Developed for Company</p>
                            </div>
                        </div>
                        <p className="font-14 mb-10">Site da famosa galeria do rock, ponto turistico de São Paulo. Você econconta vários artigos de Rock N' Roll lá.</p>
                        {/* <div className="absolute bottom-0 left-0 right-0">
                            <a href="http://andressabertolini.com/project/galeria-do-rock" target="_blank" className="shrink-border mb-10 mt-10 w-full text-center">Live</a>
                        </div> */}
                    </div>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className="bg-white border-gray shadow dark-gray h-full slide-content rounded-small overflow-hidden dark-mode-bg dark-mode-white">
                    <img src={projectHarryPotterQuebraCabeca3D} className="w-full"/>
                    <div className="p-15">
                        <div className="row mb-20">
                            <div className="col-md-6">
                                <h3 className="dark-pink font-16 m-0 bold">Harry Potter Quebra-Cabeça 3D</h3>
                                <p className="text-uppercase font-10">Landing Page do produto</p>
                            </div>
                            <div className="col-md-6 text-center">
                                <span className="bg-purple-xd tag white align-middle mb-2">
                                    <i className="icon icon-file bg-white hover-size align-middle mr-8"></i>
                                    Layout para HTML
                                </span>
                                <p className="text-uppercase font-10">Devenvolvido para Empresa</p>
                            </div>
                        </div>
                        <p className="font-14 mb-10">Essa landing page foi desenvolvida para divulgar o quebra-cabeça 3D do Harry Potter, com vários cenários do filme para montar.</p>
                        {/* <div className="absolute bottom-0 left-0 right-0">
                            <a href="http://andressabertolini.com/project/harry-potter-3d" target="_blank" className="shrink-border mb-10 mt-10 w-full text-center">Live</a>
                        </div> */}
                    </div>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className="bg-white border-gray shadow dark-gray h-full slide-content rounded-small overflow-hidden dark-mode-bg dark-mode-white">
                    <img src={projectBradescoSeguro} className="w-full"/>
                    <div className="p-15">
                        <div className="row mb-20">
                            <div className="col-md-6">
                                <h3 className="dark-pink font-16 m-0 bold">Bradesco Seguros</h3>
                                <p className="text-uppercase font-10">Landing Page</p>
                            </div>
                            <div className="col-md-6 text-center">
                                <span className="bg-purple-xd tag white align-middle mb-2">
                                    <i className="icon icon-file bg-white hover-size align-middle mr-8"></i>
                                    Layout para HTML
                                </span>
                                <p className="text-uppercase font-10">Devenvolvido para Empresa</p>
                            </div>
                        </div>
                        <p className="font-14 mb-10">Landing page para divulgar o seguro para carros da Bradesco.</p>
                        {/* <div className="absolute bottom-0 left-0 right-0">
                            <a href="http://andressabertolini.com/project/bradesco-seguros" target="_blank" className="shrink-border mb-10 mt-10 w-full text-center">Live</a>
                        </div> */}
                    </div>
                </div>
            </SwiperSlide>
            <SwiperSlide>
            <div className="bg-white border-gray shadow dark-gray h-full slide-content rounded-small overflow-hidden dark-mode-bg dark-mode-white">
                <img src={projectSulamericaAssistenciaInventario} className="w-full"/>
                <div className="p-15">
                    <div className="row mb-20">
                        <div className="col-md-6">
                            <h3 className="dark-pink font-16 m-0 bold">Sulamerica Assistência Inventário</h3>
                            <p className="text-uppercase font-10">Página de formulário</p>
                        </div>
                        <div className="col-md-6 text-center">
                            <span className="bg-purple-xd tag white align-middle mb-2">
                                <i className="icon icon-file bg-white hover-size align-middle mr-8"></i>
                                Layout para HTML
                            </span>
                            <p className="text-uppercase font-10">Devenvolvido para Empresa</p>
                        </div>
                    </div>
                    <p className="font-14 mb-10">Formulário de assistência inventário da Sulamérica, contém aplicações de vários estilos de inputs de formulário.</p>
                    {/* <div className="absolute bottom-0 left-0 right-0">
                        <a href="http://andressabertolini.com/project/sulamerica" target="_blank" className="shrink-border mb-10 mt-10 w-full text-center">Live</a>
                    </div> */}
                </div>
            </div>
            </SwiperSlide>
        </Swiper> 
        :''}
        </>
    )
}

export default HomeSlider;