import React from "react";

class CardProject extends React.Component{
    render(){
        return(
            <div class="h-100 d-block">
                <div className="bg-white border-gray shadow dark-gray h-full slide-content rounded-small overflow-hidden dark-mode-bg dark-mode-white">
                    <img src={this.props.image} className="w-full"/>
                    <div className="p-15">
                        <div className="row mb-20">
                            <div className="col-md-6">
                                <h3 className="dark-pink font-18 m-0 bold">{this.props.title}</h3>
                                <p className="text-uppercase font-10">{this.props.type}</p>
                            </div>
                            <div className="col-md-6 text-center">
                                {this.props.tecnology == 'wordpress' ?
                                <span className="bg-blue-wordpress tag white align-middle mb-2">
                                    <i className="icon icon-wordpress bg-white align-middle mr-3"></i>
                                    Wordpress
                                </span>
                                : ''}
                                {this.props.tecnology == 'woocommerce' ?
                                <span className="bg-purple-woocommerce tag white align-middle mb-2">
                                    <i className="icon icon-woocommerce align-middle mr-3"></i>
                                    WooCommerce
                                </span>
                                : ''}
                                {this.props.tecnology == 'reactjs' ? 
                                <span className="bg-blue-reactjs tag white align-middle mb-2">
                                    <i className="icon icon-reactjs bg-white align-middle mr-3"></i>
                                    Reactjs
                                </span>
                                : ''}
                                {this.props.tecnology == 'ionic' ?
                                <span className="bg-blue-ionic tag white align-middle mb-2">
                                    <i className="icon icon-ionic bg-white align-middle mr-3"></i>
                                    Ionic
                                </span>
                                : ''}
                                {this.props.tecnology == 'html' ?
                                <span className="bg-red-html tag white align-middle mb-2">
                                    <i className="icon icon-html bg-white align-middle mr-3"></i>
                                    HTML/CSS/JS
                                </span>
                                : ''}
                                {this.props.tecnology == 'angularjs' ?
                                <span className="bg-red-angular tag white align-middle mb-2">
                                    <i className="icon icon-angular bg-white align-middle mr-3"></i>
                                    AngularJS
                                </span>
                                : ''}
                                {this.props.tecnology == 'vuejs' ?
                                <span className="bg-green-vuejs tag white align-middle mb-2">
                                    <i className="icon icon-vuejs bg-white align-middle mr-3"></i>
                                    VueJS
                                </span>
                                : ''}
                                {this.props.tecnology == 'shopify' ?
                                <span className="bg-green-shopify tag white align-middle mb-2">
                                    <i className="icon icon-shopify bg-white align-middle mr-3"></i>
                                    Shopify
                                </span>
                                : ''}
                                <p className="text-uppercase font-10">{this.props.developedReason}</p>
                            </div>
                        </div>
                        <p className="font-14 mb-10">{this.props.description}</p>

                        {this.props.link ? 
                        <div className="absolute">
                            <a href={this.props.link} target="_blank">
                                <span className="shrink-border mb-10 mt-10 w-full text-center">Live</span>
                            </a>
                        </div>
                        : ''}
                    </div>
                </div>
            </div>
        )
    }
}

export default CardProject;